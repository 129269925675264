body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.StripeElement {
  padding: 16px 18px;
  width: 100%;
  background-color: #F1F3F6;
  border: none;
  border-radius: 4px;
}

.StripeElement--invalid {
  border-color: #fa755a;
}

.StripeElement--webkit-autofill {
  background-color: #fefde5 !important;
}


.custom-input-number input {
  text-align: center;

}

.custom-input-number .rs-input-number-btn-group-vertical {
  display: none;
}

/* 本体部分 */
.steps{
  overflow-y:hidden;
  display: flex;
  list-style: none;
}
li.step{
  color: #acacac;
  font-weight: 700;
  position: relative;
  padding: 10px 0px 10px 10px;
  margin: 0 30px 0 0;
  background: #e6e6e6;
  text-align: center;
  min-width: 150px;
  white-space: nowrap;
}

 /*　先端部分 */
li.step::before{
  content: "";
  position: absolute;
  top: -30px;
  right: -60px;
  border-width: 60px 30px;
  border-color: transparent transparent transparent #e6e6e6 ;
  border-style: solid;
  z-index: 10;
}

/* 白いスペース部分 */
li.step::after{
 content: "";
 position: absolute;
 top: -30px;
 right: -75px;
 border-width: 60px 30px;
 border-color: transparent transparent transparent white ;
 border-style: solid;
 z-index: 5;
}

/* 現在のステップ */
li.step.active{
  background: #405D83;
  color: white;
}
li.step.active::before{
  border-color: transparent transparent transparent #405D83 ;
}

[hidden] {display: none!important;}