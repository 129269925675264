.main_css_us {
    color: #2C2D40,
}

.main_css_jp {
    font-family: 'Noto Sans JP';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 18px;
    color: #083464;
    margin-bottom: 16px;
}